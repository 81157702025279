/* eslint-disable */
<template>
  <fragment>
    <Design :productId="productId" :designText="type" v-if="currentUser.role ==='admin'"/>
  </fragment>
</template>

<script type="text/javascript">
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import Design from './Design';
export default {
  name: "App",
  components: {
    Design
  },
  data() {
    return {
      productId: this.$route.params.id,
      type: true
    };
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      currentUser: 'authentication/currentUser',
    })
  }
};
</script>
